<template>
  <PxHeader :activeSearchMobile="false" />
  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section
    class="w-full h-full bg-agrogo-green-2 bg-agrogo-SuccessfullRegistration sizeMinAllView"
    :style="styleObject(record?.img_head_url)"
    v-else
  >
    <publish-button />
    <div
      class="flex w-full h-12 bg-agrogo-yellow-1 items-center md:h-16 md:justify-center"
    >
      <div
        class="font-nexa font-normal text-base text-left pl-6 md:pl-0 md:text-2xl"
      >
        <div class="style-info" v-html="record.title" />
      </div>
    </div>
    <div class="container-bg-successfullresitration-desktop">
      <div class="container bg-agrogo-green-2">
        <div class="bg-agrogo-green-2-transparent">
          <div
            class="flex flex-col px-6 pt-8 pb-12 space-y-12 bg-agrogo-green-2 w-full h-full md:py-10 md:space-y-10 md:px-20"
          >
            <div class="flex flex-col items-center justify-center">
              <img
                class="w-11 mb-7"
                src="@/assets/images/iconsuccessful.svg"
                alt=""
              />
              <a class="font-nexa font-normal text-base text-white md:text-2xl"
                >Hola, inicia tu vida <span class="font-black">AGRO GO</span></a
              >
            </div>
            <div class="flex items-center justify-center space-x-5">
              <button
                @click.prevent="publish()"
                type="router-link "
                class="btn-login w-40"
              >
                Vender Finca
              </button>
              <router-link
                to="/lista-de-fincas"
                type="router-link"
                class="btn-login w-40"
              >
                Comprar Finca
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
import PxHeader from "@/components/header/PxHeader";
import { mapState } from "vuex";
import PublishButton from "@/components/publishButton/PublishButton";

export default {
  inject: ["provider"],
  data() {
    return {
      repository: this.provider.publishFarmRepository,
      record: null,
      isBusy: true,
    };
  },
  computed: {
    ...mapState("user", ["isAuthenticated"]),
  },
  components: {
    PxHeader,
    PublishButton,
  },
  methods: {
    publish() {
      const me = this;
      if (me.isAuthenticated) {
        me.$router.push({
          name: "publicar",
        });
      } else {
        me.$router.push({
          name: "Login",
          query: { redirect: "publicar" },
        });
      }
    },
    async loadData() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllNames("REGISTER_SUCCESS", dataAditional);
        me.record = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    styleObject(url) {
      let img = null;
      img = url
        ? `${API_ROUTES.image.get}${url}`
        : require("@/assets/images/paisajesuccessfullregistrationdesktop.jpg");
      return { backgroundImage: `url(${img})` };
    },
  },
  mounted() {
    const me = this;
    window.scroll(0, 0);
    me.loadData();
  },
};
</script>
